import React, { useEffect, useState } from "react";

import "../../styles/WorkdaysLabel.css";

const WorkdaysLabel = () => {
  const time = new Date();
  const day = time.toLocaleString("en-us", {
    weekday: "long",
    timeZone: "AST",
  });
  const morning = time.getHours() > 5 && time.getHours() <= 12;
  const afternoon = time.getHours() > 12 && time.getHours() <= 18;
  const evening = time.getHours() > 18 && time.getHours() <= 22;
  const night = time.getHours() > 22 && time.getHours() <= 5;
  let dayMessage;

  if (day.toLowerCase() !== "friday") {
    dayMessage = `${day}, Work day`;
  } else {
    dayMessage = `${day}, Off day`;
  }

  const [checkDay, setCheckday] = useState("off-day");

  useEffect(() => {
    day.toLowerCase() !== "friday"
      ? setCheckday("work-day")
      : setCheckday("off-day");
  }, [day]);

  return (
    <div className={`workdays-label ${checkDay}`}>
      {morning ? (
        <p>
          <i className="ri-sun-foggy-line" /> Good Morning!
        </p>
      ) : (
        ""
      )}
      {afternoon ? (
        <p>
          <i className="ri-sun-line" /> Good Afternoon!
        </p>
      ) : (
        ""
      )}
      {evening ? (
        <p>
          <i className="ri-sun-foggy-line" /> Good Evening!
        </p>
      ) : (
        ""
      )}
      {night ? (
        <p>
          <i className="ri-moon-line" /> Good Night!
        </p>
      ) : (
        ""
      )}
      <p>
        {dayMessage}
        {checkDay === "work-day" ? (
          <i className="ri-sparkling-2-line" />
        ) : (
          <i className="ri-pause-circle-line" />
        )}
      </p>
    </div>
  );
};

export default WorkdaysLabel;
