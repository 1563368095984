import React, { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import "../../styles/ProjectsWrapper.css";

import ProjectsCover from "./ProjectsCover.jsx";

import NassemAlfaihaa from "../../assets/naseem-alfaihaa-project-cover.webp";
import ImadTech from "../../assets/imad-tech-project-cover.webp";
import Dupamicaffeine from "../../assets/dupamicaffeine-project-cover.webp";
import ArabGT from "../../assets/arab-gt-project-cover.webp";

gsap.registerPlugin(ScrollTrigger);

const ProjectsWrapper = () => {
  const target = useRef(null);

  useGSAP(() => {
    const el = target.current;
    const ctx = gsap.context(() => {
      const tl = gsap.timeline();
      tl.fromTo(
        el,
        {
          y: 0,
        },
        {
          y: "-=200",
          scrollTrigger: {
            trigger: el,
            start: "top 70%",
            scrub: true,
          },
        }
      );
    });

    return () => ctx.revert;
  });

  return (
    <section className="projects-wrapper" ref={target}>
      <ProjectsCover
        link="/projects/naseem-alfaihaa"
        src={NassemAlfaihaa}
        alt="Nassem Al Faihaa"
        projectTitle="Nassem Al Faihaa"
        date="14 June 2014"
        category="Brand Identity | Iraq"
      />

      <ProjectsCover
        link="/projects/imad-tech"
        src={ImadTech}
        alt="Imad Tech"
        projectTitle="Imad tech"
        date="14 June 2014"
        category="Brand Identity | Iraq"
      />

      <ProjectsCover
        link="/projects/dupamicaffeine"
        src={Dupamicaffeine}
        alt="Dupamicaffeine"
        projectTitle="Dupamicaffeine"
        date="14 June 2014"
        category="Brand Identity | Iraq"
      />

      <ProjectsCover
        link="/projects/arab-gt"
        src={ArabGT}
        alt="Arab GT"
        projectTitle="Arab GT"
        date="14 June 2014"
        category="Brand Identity | Iraq"
      />
    </section>
  );
};

export default ProjectsWrapper;
