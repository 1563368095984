import React, { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "../../styles/ProjectsDetails.css";

gsap.registerPlugin(ScrollTrigger);

const ProjectsDetails = ({
  projectTitle,
  role,
  credits,
  locationYear,
  projectCoverSrc,
  projectCoverAlt,
}) => {
  const target = useRef(null);

  useGSAP(() => {
    const el = target.current;
    const ctx = gsap.context(() => {
      const tl = gsap.timeline();
      tl.fromTo(
        el,
        {
          y: 0,
        },
        {
          y: "-=200",
          scrollTrigger: {
            trigger: el,
            start: "top 70%",
            scrub: true,
          },
        }
      );
    });

    return () => ctx.revert;
  });

  return (
    <>
      <div className="projects-details container">
        <div className="project-title">
          <h1 className="fs-primary-heading">{projectTitle}</h1>
        </div>
        <div className="project-caption-wrapper">
          <div className="project-caption">
            <small>Role / Service</small>
            <span>{role}</span>
          </div>
          <div className="project-caption">
            <small>Credits</small>
            <span>{credits}</span>
          </div>
          <div className="project-caption">
            <small>Location & Year</small>
            <span>{locationYear}</span>
          </div>
        </div>
      </div>
      <img
        className="project-cover"
        src={projectCoverSrc}
        alt={projectCoverAlt}
        ref={target}
      />
    </>
  );
};

export default ProjectsDetails;
