import React from "react";
import { useLocation } from "react-router-dom";

import ProjectsDetails from "./ProjectsDetails";

import NaseemAlfaihaa from "../../assets/naseem-alfaihaa-project-cover.webp";
import ImadTech from "../../assets/imad-tech-project-cover.webp";

const Case = () => {
  const location = useLocation();

  return (
    <div>
      {location.pathname === "/projects/naseem-alfaihaa" ? (
        <ProjectsDetails
          projectTitle="Naseem Al-Faihaa&reg;"
          role="Branding"
          credits="Ramo Creative"
          locationYear="Baghdad, Iraq &#169;2023"
          projectCoverSrc={NaseemAlfaihaa}
          projectCoverAlt="Naseem Al-Faihaa&reg;"
        />
      ) : (
        ""
      )}
      {location.pathname === "/projects/imad-tech" ? (
        <ProjectsDetails
          projectTitle="iMAD Tech&trade;"
          role="Branding"
          credits="Ramo Creative"
          locationYear="Baghdad, Iraq &#169;2023"
          projectCoverSrc={ImadTech}
          projectCoverAlt="iMAD Tech&trade;"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Case;
