import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 1000)
  }, [navigate]);


  return (
    <div className='not-found' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100dvh'}}>
      <h1 className='fs-primary-heading' style={{marginBottom: 'var(--size-900)'}}>Page Not Found <br /> 404</h1>
    </div>
  );
}

export default NotFound