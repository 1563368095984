import React from 'react';

import Title from '../../components/Title.jsx'
import Button from '../../components/Button.jsx'
import ProjectCrad from './ProjectCard.jsx';

import '../../styles/RecentWorks.css'

const RecentWorks = () => {
  return (
    <section className='recent-works container'>
      <div className='header-section'>
        <Title title='Recent Works' />
        <Button href='/projects' text='More' icon={<i className="ri-arrow-right-line"></i>} />
      </div>
      <div className="cards-wrapper">
        <ProjectCrad projectTitle='Project Title Placeholder' date='2024 April 17' category='Brand Identity' region='Baghdad, Iraq' />
        <ProjectCrad projectTitle='Project Title Placeholder' date='2024 April 17' category='Brand Identity' region='Baghdad, Iraq' />
        <ProjectCrad projectTitle='Project Title Placeholder' date='2024 April 17' category='Brand Identity' region='Baghdad, Iraq' />
        <ProjectCrad projectTitle='Project Title Placeholder' date='2024 April 17' category='Brand Identity' region='Baghdad, Iraq' />
        <ProjectCrad projectTitle='Project Title Placeholder' date='2024 April 17' category='Brand Identity' region='Baghdad, Iraq' />
      </div>
    </section>
  );
}

export default RecentWorks