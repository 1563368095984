import React from 'react';

import '../../styles/MenuButton.css';

const MenuButton = () => {
  return (
    <button className='menu-button' onClick={() => {document.documentElement.toggleAttribute('menu-open')}}>
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
}

export default MenuButton