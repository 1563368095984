import React from 'react';
import { Link } from 'react-router-dom';

import '../styles/Button.css'

const Button = ({href, text, icon}) => {
  return (
    <Link to={href} className='button-container'>
      <button className='button'>{text}{icon}</button>
    </Link>
  );
}

export default Button