import React from 'react';

import '../styles/Title.css'

const Title = ({title}) => {
  return (
    <div className='title'>
      <h2 className='fs-secondary-heading'>{title}</h2>
    </div>
  );
}

export default Title