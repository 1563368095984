import React from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

import "../styles/WebsiteIntro.css";

const WebsiteIntro = () => {
  useGSAP(() => {
    document.querySelector(".app").classList.add("is-loading");
    const ctx = gsap.context(() => {
      const tl = gsap.timeline();
      tl.from("#title", {
        opacity: 0,
        y: "+=30",
      })
        .to("#title", {
          opacity: 0,
          y: "-=30",
        })
        .to("#intro-slider", {
          xPercent: "100",
          duration: 1.3,
        })
        .from("#main", {
          y: "+=100",
          opacity: 0,
          duration: 1.3,
          onComplete: () =>
            document.querySelector(".app").classList.remove("is-loading"),
        });
    });

    return () => ctx.revert;
  }, []);
  return (
    <div className="website-intro" id="intro-slider">
      <h1 className="fs-primary-heading" id="title">
        Placeholder
      </h1>
    </div>
  );
};

export default WebsiteIntro;
