import React from 'react';
import { Link } from 'react-router-dom';

import '../../styles/ProjectsCover.css';

const ProjectsCover = ({link, src, alt, projectTitle, date, category}) => {

  return (
    <Link to={link} className="projects-cover">
      <img src={src} alt={alt} />
      <h3>{projectTitle}</h3>
      <small>Published in {date}</small>
      <p>{category}</p>
    </Link>
  );
}

export default ProjectsCover