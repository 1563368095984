import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from "lenis";

import "./App.css";

import WebsiteIntro from "./components/WebsiteIntro.jsx";
import ScrollRestoration from "./components/ScrollRestoration.jsx";
import ScrollToTop from "./components/ScrollToTop.jsx";
import Navbar from "./Layout/Navbar/Navbar.jsx";
import Footer from "./Layout/Footer/Footer.jsx";
import Home from "./pages/Home/Home.js";
import Projects from "./pages/Projects/Projects.js";
import Case from "./pages//Projects/Case.js";
import About from "./pages/About/About.js";
import NotFound from "./pages/NotFound.js";

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  const location = useLocation();
  console.log(location.pathname);

  const lenis = new Lenis();

  lenis.on("scroll", ScrollTrigger.update);

  gsap.ticker.add((time) => {
    lenis.raf(time * 800);
  });

  gsap.ticker.lagSmoothing(0);

  const [theme, setTheme] = useState("light");

  return (
    <div className={`app ${theme}`}>
      {location.pathname === "/" ? <WebsiteIntro /> : ""}
      <ScrollRestoration />
      <ScrollToTop />
      <Navbar theme={theme} setTheme={setTheme} />
      <main id="main">
        <Routes location={location} key={location.pathname}>
          <Route index element={<Home />} />
          <Route path="/projects">
            <Route index element={<Projects />} />
            <Route path="/projects/*" element={<Case />} />
          </Route>
          <Route path="/about" element={<About />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;
