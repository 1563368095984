import React from "react";

import "../../styles/ProjectsHero.css";

const ProjectsHero = () => {
  return (
    <section className="project-hero container">
      <h1 className="fs-primary-heading">
        Let's make your ideas <br /> become reality
      </h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatem
        consectetur vitae, commodi sit laudantium cum voluptatum, praesentium
        reprehenderit quisquam officia recusandae possimus sapiente. Nobis cum
        exercitationem doloribus harum ipsam facilis.
      </p>
    </section>
  );
};

export default ProjectsHero;
