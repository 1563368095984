import React from "react";

import "../../styles/Hero.css";

import Socials from "../../components/Socials.jsx";
import Button from "../../components/Button";

const Hero = () => {
  return (
    <>
      <section className="hero container">
        <h1 className="fs-primary-heading">
          Hello World!
          <br />
          <span>
            I'm Ramo & I'm a Branding
            <br />
            Designer
          </span>
        </h1>
        <p>
          My name's Raman, known as “Ramo Creative”. A passionate graphic
          designer specializing in crafting eye-catching logos and impactful
          brand experiences, based in Iraq.
        </p>
        <Socials />
        <Button
          href="/"
          text="Click me"
          icon={<i className="ri-arrow-right-line"></i>}
        />
      </section>
    </>
  );
};

export default Hero;
