import React from "react";

import ProjectsHero from "./ProjectsHero";
import ProjectsWrapper from "./ProjectsWrapper";

const Projects = () => {
  return (
    <div className="projects">
      <ProjectsHero />
      <ProjectsWrapper />
    </div>
  );
};

export default Projects;
