import React from 'react';

import '../../styles/ProjectCard.css'

const ProjectCard = ({projectTitle, date, category, region}) => {
  return (
    <div className="project-card">
      <img src="https://placehold.co/120x120" alt="Project" />
      <div className='project-details'>
        <h3 className='fs-tertiary-heading'>{projectTitle}</h3>
        <small>Published in {date}</small>
        <p>{category} | {region}</p>
      </div>
      <div className="view-project-icon">
        <i className="ri-arrow-right-up-line"></i>
      </div>
    </div>
  );
}

export default ProjectCard