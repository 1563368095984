import React, { useEffect, useState } from 'react';

import '../styles/ScrollToTop.css'

const ScrollToTop = () => {

  const [checkScroll, setCheckScroll] = useState(false);

  const handleScrollButton = () => {
    window.scrollY > 50 ? setCheckScroll(true) : setCheckScroll(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => handleScrollButton());

    return () => {
      window.removeEventListener('scroll', () => handleScrollButton());
    };
  }, []);

  return (
    <div className={`scroll-to-top ${checkScroll ? 'active' : ''}`} onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'})}}>
      <i className="ri-arrow-up-line"></i>
    </div>
  );
}

export default ScrollToTop