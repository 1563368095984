import React from "react";
import { Link } from "react-router-dom";

import "../../styles/Footer.css";

import Socials from "../../components/Socials.jsx";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer container">
      <div className="links">
        <nav>
          <ul>
            <li>
              <Link to="/" className="Home">
                Home
              </Link>
            </li>
            <li>
              <Link to="/projects" className="projects">
                Projects
              </Link>
            </li>
            <li>
              <Link to="/about" className="about">
                About
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="socials-wrapper">
        <span>Socials</span>
        <Socials />
      </div>
      <small to="/" className="logo">
        Ramo Creative &#169;{year}, All rights reserved
      </small>
    </footer>
  );
};

export default Footer;
