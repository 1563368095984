import React from 'react';

import '../../styles/NavIcons.css';
import MenuButton from './MenuButton';

const NavIcons = ({theme, setTheme}) => {

  const toggleMode = () => {
    theme === 'light' ? setTheme('dark') : setTheme('light');
  };

  return (
    <div className='nav-icons'>
      <i className={theme === 'light' ? 'ri-moon-line' : 'ri-sun-line'} onClick={() => {toggleMode()}}></i>
      <i className="ri-chat-smile-2-line"></i>
      <MenuButton />
    </div>
  );
}

export default NavIcons