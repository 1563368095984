import React from "react";

import Hero from "./Hero.jsx";
import RecentWorks from "./RecentWorks.jsx";

const Home = () => {
  return (
    <div className="home">
      <Hero />
      <RecentWorks />
    </div>
  );
};

export default Home;
