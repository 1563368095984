import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import "../../styles/Navbar.css";

import NavIcons from "./NavIcons.jsx";
import WorkdaysLabel from "./WorkdaysLabel.jsx";

const Navbar = ({ theme, setTheme }) => {
  const [sticky, setSticky] = useState(false);

  const handleNavSticky = () => {
    window.scrollY > 50 ? setSticky(true) : setSticky(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => handleNavSticky());

    return () => {
      window.removeEventListener("scroll", () => handleNavSticky());
    };
  }, []);

  const handleNavigation = () => {
    document.documentElement.removeAttribute("menu-open");
  };

  return (
    <header
      className={`navbar container ${sticky ? "active" : ""} ${theme}`}
      id="navbar"
      header=""
    >
      <Link
        to="/"
        className="logo"
        style={{ textDecoration: "none", color: "var(--clr-neutral-400)" }}
        onClick={() => {
          handleNavigation();
        }}
      >
        &#169; Ramo Creative
      </Link>
      <WorkdaysLabel />
      <nav>
        <ul>
          <li>
            <NavLink
              end
              to="/"
              className="Home"
              onClick={() => {
                handleNavigation();
              }}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              end
              to="/projects"
              className="projects"
              onClick={() => {
                handleNavigation();
              }}
            >
              Projects
            </NavLink>
          </li>
          <li>
            <NavLink
              end
              to="/about"
              className="about"
              onClick={() => {
                handleNavigation();
              }}
            >
              About
            </NavLink>
          </li>
        </ul>
      </nav>
      <NavIcons theme={theme} setTheme={setTheme} />
    </header>
  );
};

export default Navbar;
