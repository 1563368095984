import React from "react";
import { Link } from "react-router-dom";

import "../styles/Socials.css";

const Socials = () => {
  return (
    <div className="socials">
      <ul>
        <li>
          <Link to="https://instagram.com/ramocreative" target="_blank">
            <i className="ri-instagram-line" />
          </Link>
        </li>
        <li>
          <Link to="https://twitter.com/ramocreative" target="_blank">
            <i className="ri-twitter-x-line" />
          </Link>
        </li>
        <li>
          <Link to="https://behance.net/ramocreative" target="_blank">
            <i className="ri-behance-line" />
          </Link>
        </li>
        <li>
          <Link to="https://linkedin.com/ramocreative" target="_blank">
            <i className="ri-linkedin-line" />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Socials;
